import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import logo from '../../assets/images/logo-1.png';
import sharepic from '../../assets/images/hoc-bag.jpg';

export default function SEO({
  children,
  location,
  description,
  title,
  image,
  schemaMarkup,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );
  return (
    <Helmet
    // titleTemplate={`%s - ${site.siteMetadata.title}`}
    >
      <html lang="en" />
      <title>{title}</title>
      <link rel="icon" type="image/svg+xml" href={logo} />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta
        name="description"
        content={description || site.siteMetadata.description}
      />

      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={image || sharepic} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:site_name"
        content={site.siteMetadata.title}
        key="ogsitename"
      />
      <meta property="og:description" content={description} />
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      {children}
    </Helmet>
  );
}
